import { useStaticQuery, graphql } from 'gatsby'

export const useChannelManagerImages = () => {
  const images = useStaticQuery(graphql`
    fragment ChannelManagerFluidImage on File {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    query {
      ChannelManagerBack: file(relativePath: { eq: "images/channel-manager-back.jpg" }) {
        ...ChannelManagerFluidImage
      }
      ChannelManagerFront: file(relativePath: { eq: "images/channel-manager-front.png" }) {
        ...ChannelManagerFluidImage
      }
      ChannelManagerLeft: file(relativePath: { eq: "images/channel-manager-left.png" }) {
        ...ChannelManagerFluidImage
      }
      ChannelManagerRight: file(relativePath: { eq: "images/channel-manager-right.png" }) {
        ...ChannelManagerFluidImage
      }
      ChannelManagerChannels: file(relativePath: { eq: "images/cm-kanali.jpg" }) {
        ...ChannelManagerFluidImage
      }
      ChannelManagerBooking: file(relativePath: { eq: "images/cm-closeup.png" }) {
        ...ChannelManagerFluidImage
      }
      ChannelManagerMiddle: file(relativePath: { eq: "images/channel-manager-middle.png" }) {
        ...ChannelManagerFluidImage
      }
      ChannelManagerHero: file(relativePath: { eq: "images/channel-manager-hero.jpg" }) {
        ...ChannelManagerFluidImage
      }
    }
  `)

  return images
}
