import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'

import SEO from 'components/SEO'
import Layout from 'components/Layout'
import Header from 'components/Header'
import Footer from 'components/Footer'
import Intro from 'components/Intro'
import ChannelManagerContent from 'components/Channel-manager-content'
import CallToAction from 'components/CTA'
import TestimonialCarousel from 'components/UI/Testimonial-carousel'

import { useCmImages } from '../graphql/useCmImagesHook'

const MainContent = styled.main`
  background-color: #ffffff;
`

const ChannelManager = ({ data, pageContext, location }) => {
  const { CmIntro } = useCmImages()

  const {
    cm: {
      featured_media,
      title,
      excerpt,
      yoast_meta: { yoast_wpseo_title, yoast_wpseo_metadesc },
      acf: { intro_title_cm, subtitle_cm, content_title_cm, content_features_cm, testimonials_cm, cta_cm }
    }
  } = data

  const featuredImage = featured_media !== null ? featured_media?.localFile?.childImageSharp : null
  
  
  const seo = {
    title: yoast_wpseo_title || title,
    description: yoast_wpseo_metadesc || excerpt,
    image: featuredImage ? featuredImage.resize : null,
    lang: pageContext.locale,
    pathname: location.pathname
  }
  
  return (
    <Layout locale={pageContext.locale}>
      <SEO
        title={seo.title}
        description={seo.description}
        image={seo.image}
        pathname={seo.pathname}
        lang={seo.lang}
        location={location}
      />
      <Header mode="light" inverted="true" locale={pageContext.locale} color={'#BE4806'} location={location} />
      <Intro
        title={intro_title_cm}
        subtitle={subtitle_cm}
        introImg={CmIntro.childImageSharp.fluid}
        pageName="cm"
        yAxis={13}
      />
      <MainContent>
        <ChannelManagerContent content={{ content_title_cm, content_features_cm }} />
        <TestimonialCarousel testimonials={testimonials_cm} />
        <CallToAction data={cta_cm} locale={pageContext.locale} location={location} />
      </MainContent>
      <Footer locale={pageContext.locale} location={location} />
    </Layout>
  )
}

export default ChannelManager

export const query = graphql`
  query($id: Int, $locale: String!) {
    cm: wordpressPage(wordpress_id: { eq: $id }, lang: { eq: $locale }) {
      title
      excerpt
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
      }
      featured_media {
        localFile {
          childImageSharp {
            fluid(quality: 85, maxWidth: 1300) {
              ...GatsbyImageSharpFluid_withWebp
            }
            resize(width: 1200) {
              src
              height
              width
            }
          }
        }
      }
      acf {
        intro_title_cm
        subtitle_cm
        content_title_cm
        content_features_cm {
          title
          description
        }
        testimonials_cm {
          description
          name
          property
        }
        cta_cm {
          title
          description
        }
      }
    }
  }
`
