import React, { useEffect } from 'react'
import Img from 'gatsby-image'
import Rellax from 'rellax'

import { ChannelManagerImages } from './styles'

const ChannelManagerHeroImages = ({ images }) => {
  const {
    ChannelManagerMiddle,
    ChannelManagerBack,
    ChannelManagerFront,
    ChannelManagerLeft,
    ChannelManagerRight
  } = images

  useEffect(() => {
    const rellax = new Rellax('.parallax-images', {
      center: true,
      vertical: true,
      horizontal: true
    })
  }, [])

  return (
    <ChannelManagerImages>
      <figure>
        <Img fluid={ChannelManagerBack.childImageSharp.fluid} />
      </figure>
      <figure
        className="parallax-images"
        data-rellax-min-y="-40"
        data-rellax-max-y="10"
        data-rellax-min-x="0"
        data-rellax-max-x="0"
        data-rellax-vertical-speed="-1"
        data-rellax-vertical-scroll-axis="y"
      >
        <Img fluid={ChannelManagerFront.childImageSharp.fluid} />
      </figure>
      <figure
        className="parallax-images"
        data-rellax-min-y="-10"
        data-rellax-max-y="10"
        data-rellax-min-x="-100"
        data-rellax-max-x="160"
        data-rellax-horizontal-speed="-2"
        data-rellax-vertical-scroll-axis="x"
      >
        <Img fluid={ChannelManagerLeft.childImageSharp.fluid} />
      </figure>
      <figure
        className="parallax-images"
        data-rellax-min-y="-10"
        data-rellax-max-y="10"
        data-rellax-min-x="-100"
        data-rellax-max-x="150"
        data-rellax-horizontal-speed="-2"
        data-rellax-vertical-scroll-axis="y"
      >
        <Img fluid={ChannelManagerMiddle.childImageSharp.fluid} />
      </figure>
      <figure
        className="parallax-images"
        data-rellax-min-y="-15"
        data-rellax-max-y="150"
        data-rellax-min-x="0"
        data-rellax-max-x="0"
        data-rellax-vertical-speed="3"
        data-rellax-vertical-scroll-axis="y"
      >
        <Img fluid={ChannelManagerRight.childImageSharp.fluid} />
      </figure>
    </ChannelManagerImages>
  )
}

export default ChannelManagerHeroImages
