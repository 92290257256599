import { useStaticQuery, graphql } from 'gatsby'

export const useCmImages = () => {
  const images = useStaticQuery(graphql`
    fragment CmFluidImage on File {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    query {
      CmIntro: file(relativePath: { eq: "images/channel-manager-illustration.png" }) {
        ...CmFluidImage
      }
    }
  `)

  return images
}
