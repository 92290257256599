import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'

import {
  ChannelManagerSection,
  ChannelManagerWrapper,
  Title,
  Description,
  ChannelManagerContentImages,
  ChannelManagerFeatures,
  ImageContainer
} from './styles'

import Responsive from 'components/Responsive'
import WindowSizeProvider from 'components/WindowSizeProvider'
import Container from '../UI/Container'
import ChannelManagerHeroImages from './ChannelManagerHero'
import { useChannelManagerImages } from '../../graphql/useChannelManagerImagesHook'

const ChannelManagerContent = ({ content }) => {
  const { content_title_cm, content_features_cm } = content
  const {
    ChannelManagerBack,
    ChannelManagerFront,
    ChannelManagerLeft,
    ChannelManagerRight,
    ChannelManagerMiddle,
    ChannelManagerChannels,
    ChannelManagerBooking,
    ChannelManagerHero
  } = useChannelManagerImages()

  const bookingImage = useRef()
  const handleFadeInAnimation = () => {
    const entryOptions = {
      threshold: 0.4
    }

    const featureObserver = new IntersectionObserver((entries, featureObserver) => {
      entries.forEach((entry) => {
        if (!entry.isIntersecting) {
          return
        }

        if (entry.intersectionRatio > 0) {
          if (entry.intersectionRatio > 0) {
            entry.target.style.animation = `fadeInFromBottom 1s ${entry.target.dataset.delay}  forwards ease-in-out`
            featureObserver.unobserve(entry.target)
          }
        }
      })
    }, entryOptions)

    featureObserver.observe(bookingImage.current)
  }

  useEffect(() => {
    handleFadeInAnimation()
  }, [])

  return (
    <ChannelManagerSection>
      <Container>
        <ChannelManagerWrapper>
          <Title align="center" width="70%" margin="5">
            {content_title_cm}
          </Title>
          <WindowSizeProvider>
            <Responsive
              breakPoint={1024}
              renderDesktop={() => (
                <ChannelManagerHeroImages
                  images={{
                    ChannelManagerMiddle,
                    ChannelManagerBack,
                    ChannelManagerFront,
                    ChannelManagerLeft,
                    ChannelManagerRight
                  }}
                />
              )}
              renderMobile={() => (
                <ImageContainer>
                  <Img fluid={ChannelManagerHero.childImageSharp.fluid} />
                </ImageContainer>
              )}
            />
          </WindowSizeProvider>
        </ChannelManagerWrapper>
        <ChannelManagerFeatures>
          <div>
            <Title align="left" margin="0">
              {content_features_cm[0].title}
            </Title>
            <Description
              align="left"
              margin="2rem 0"
              dangerouslySetInnerHTML={{ __html: content_features_cm[0].description }}
            />
          </div>
          <div>
            <Title align="left" margin="0">
              {content_features_cm[1].title}
            </Title>
            <Description
              align="left"
              margin="2rem 0"
              dangerouslySetInnerHTML={{ __html: content_features_cm[1].description }}
            />
          </div>
        </ChannelManagerFeatures>
        <ChannelManagerContentImages>
          <figure>
            <Img fluid={ChannelManagerChannels.childImageSharp.fluid} />
          </figure>
          <figure ref={bookingImage} data-delay=".1s">
            <Img fluid={ChannelManagerBooking.childImageSharp.fluid} />
          </figure>
        </ChannelManagerContentImages>
        <ChannelManagerFeatures>
          <div>
            <Title align="left" margin="0">
              {content_features_cm[2].title}
            </Title>
            <Description
              align="left"
              margin="2rem 0"
              dangerouslySetInnerHTML={{ __html: content_features_cm[2].description }}
            />
          </div>
          <div>
            <Title align="left" margin="0">
              {content_features_cm[3].title}
            </Title>
            <Description
              align="left"
              margin="2rem 0"
              dangerouslySetInnerHTML={{ __html: content_features_cm[3].description }}
            />
          </div>
        </ChannelManagerFeatures>
      </Container>
    </ChannelManagerSection>
  )
}

export default ChannelManagerContent

ChannelManagerContent.propTypes = {
  content: PropTypes.shape({
    content_title_cm: PropTypes.string,
    content_features_cm: PropTypes.arrayOf(PropTypes.object)
  })
}
