import styled from 'styled-components'

export const ChannelManagerSection = styled.section`
  padding: 12rem 0 0;
  overflow: hidden;

  @media (max-width: 425px) {
    padding: 2rem 0 0;
  }
`

export const ChannelManagerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const ChannelManagerFeatures = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 6rem 0;

  div {
    width: 530px;

    @media screen and (max-width: 425px) {
      width: 100%;
    }
  }

  @media screen and (max-width: 425px) {
    margin-bottom: 0;
  }

  @media screen and (max-width: 1024px) {
    flex-direction: column;
    align-items: center;
  }
`
export const Title = styled.h2`
  color: ${({ theme }) => theme.colors.veryDarkBlue};
  font-family: 'Barlow';
  font-size: 2.85rem;
  font-weight: bold;
  line-height: 3.28rem;
  text-align: ${({ align }) => align};
  margin-bottom: ${({ margin }) => (margin ? `${margin}rem` : `0`)};
  width: ${({ width }) => width};

  @media screen and (max-width: 425px) {
    font-size: 2rem;
    line-height: 2.5rem;
    margin: 1rem 0;
  }

  @media screen and (max-width: 1180px) {
    width: 100%;
  }
`
export const Description = styled.div`
  color: ${({ theme }) => theme.colors.darkGrey};
  font-size: 1.14rem;
  line-height: inherit;
  text-align: ${({ align }) => align};
  margin: ${({ margin }) => (margin ? margin : `0`)};
  ${({ width }) => `width: ${width || `100%`}`};

  @media screen and (max-width: 425px) {
    width: 100%;
    text-align: left;
    margin-bottom: 5rem;
  }
`
export const ChannelManagerContentImages = styled.div`
  display: flex;
  position: relative;
  margin: 13rem auto;
  max-width: 1000px;
  width: 100%;

  figure {
    position: relative;

    &:nth-of-type(1) {
      width: 100%;
      height: auto;
    }

    &:nth-of-type(2) {
      max-width: 260px;
      width: 100%;
      z-index: 1;
      position: absolute;
      left: 0;
      top: 20%;
      position: absolute;
      opacity: 0;

      @keyframes fadeInFromBottom {
        from {
          transform: translate(70%, -50%);
        }

        to {
          transform: translate(70%, -65%);
          opacity: 1;
        }
      }
    }
  }

  @media (max-width: 425px) {
    margin: 1rem auto;

    figure {
      &:nth-of-type(2) {
        max-width: 150px;
      }
    }

    @keyframes fadeInFromBottom {
      from {
        transform: translate(40%, -50%);
      }

      to {
        transform: translate(40%, -65%);
        opacity: 1;
      }
    }
  }
`
export const ImageContainer = styled.figure`
  width: 100%;
`
