import styled from 'styled-components'

export const ChannelManagerImages = styled.div`
  display: flex;
  position: relative;
  margin: 10rem 0;
  max-width: 900px;
  width: 100%;
  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    bottom: 0px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
  }

  figure {
    position: relative;

    &:nth-of-type(1) {
      width: 100%;
      height: auto;
    }

    &:nth-of-type(2) {
      max-width: 330px;
      width: 100%;
      z-index: 2;
      position: absolute;
      right: 20%;
      top: 15%;
      position: absolute;
      transform: translate(-18%, -95%);
    }

    &:nth-of-type(3) {
      max-width: 590px;
      width: 100%;
      height: auto;
      z-index: 1;
      position: absolute;
      bottom: -9%;
      left: -25%;

      transform: translateX(-25%);
    }

    &:nth-of-type(4) {
      max-width: 500px;
      width: 100%;
      height: auto;
      z-index: 1;
      position: absolute;
      right: -16%;
      top: 40%;
      transform: translateX(20%);
    }

    &:nth-of-type(5) {
      max-width: 500px;
      width: 100%;
      height: auto;
      z-index: 1;
      position: absolute;
      right: -10%;
      top: -10%;
      transform: translateX(20%);
    }

    @media screen and (max-width: 425px) {
      &:nth-of-type(1),
      &:nth-of-type(3) {
        width: 190px;
      }
      &:nth-of-type(2) {
        width: 250px;
      }
    }
  }
`
